.page-cover-2 {
    max-width: 500px;
    margin: 0 auto;
    overflow: hidden;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        font-size: 14px;
        margin: 0px 10px 0px 10px;

        .icon {
            margin-left: 0px;
        }

        .text {
            color: var(--grayscale-gray-800, #3d3d3d);
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    .body {
        margin: 20px;
        display: flex;
        flex-direction: column;

        .style {
            margin-bottom: 10px;

            &__header {
                &--title {
                    color: var(--grayscale-gray-900, #222);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.16px;
                }
            }

            &__body {
                display: flex;
                overflow: auto;
                scrollbar-width: none;
                -ms-overflow-style: none;

                .type {
                    width: max-content;
                    margin-right: 20px;
                    margin-top: 5px;

                    &__box--null {
                        background-color: black;
                        width: 125px;
                        height: 165px;
                        border-radius: 8px;
                        border: 1px dashed var(--grayscale-gray-300, #d1d1d1);
                        background: var(--grayscale-gray-100, #f6f6f6);
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        // &--icon {}
                    }

                    &__box--notnull {
                        border-radius: 8px;
                        width: 125px;
                        height: 165px;
                        flex-shrink: 0;
                        background: var(--grayscale-gray-100, #f6f6f6);

                        .image-box {
                            overflow: hidden;
                            position: relative;
                            align-items: center;
                            justify-content: center;
                            display: flex;
                            width: 100%;
                            height: 100%;
                            border-radius: 8px;

                            .image {
                                object-fit: cover;
                                height: 100%;
                                width: 100%;
                            }

                            .button {
                                background-color: #f6f6f6;
                                position: absolute;
                                bottom: 0px;
                                padding: 5px;
                                width: 100%;
                                text-align: center;
                                border: 1px solid #000000;
                                border-radius: 8px;
                            }
                        }
                    }

                    &__title {
                        margin-top: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 125px;

                        &--text {
                            color: var(--grayscale-gray-900, #222);
                            text-align: center;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                        }
                    }
                }
            }

            &__body::-webkit-scrollbar {
                width: 0 !important;
                display: none;
            }
        }
    }
}

.modal {
    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 20px;

        .style-title {
            color: var(--grayscale-gray-900, #222);
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.16px;
        }

        .type-title {
            color: var(--grayscale-gray-900, #222);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.16px;
        }

        .warnig {
            color: var(--grayscale-gray-800, #3D3D3D);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.16px;
        }
    }

    .body {
        .item-box {
            height: 240px;
            flex-shrink: 0;

            display: flex;
            align-items: center;
            justify-content: center;

            .item-null {
                border: 2px dashed var(--grayscale-gray-300, #d1d1d1);
                background: var(--grayscale-gray-100, #f6f6f6);
                width: 100%;
                height: 100%;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .custom-file-input {
                    display: none;
                }

                .icon {
                    width: 45px;
                }

                .text {
                    color: var(--grayscale-gray-500, #a3a3a3);
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.16px;
                }
            }

            .item-notnull {
                position: relative;
                width: 100%;
                height: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
                overflow: hidden;

                .edit-button {
                    cursor: pointer;
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    right: 0px;
                    top: 0px;
                    background-color: #ffcc33;
                    border-bottom-left-radius: 6px;
                    justify-content: center;
                    display: flex;
                    align-items: center;

                    .custom-file-input {
                        display: none;
                    }

                    .edit-icon {
                        width: 20px;
                    }
                }

                .image {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }

    .footer {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .save-button {
            width: 200px;
            border-radius: 4px;
            background: var(--primary-yellow-500, #fc3);
            color: var(--grayscale-gray-800, #3d3d3d);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }

        .save-button2 {
            width: 200px;
            border-radius: 4px;
            // background: var(--primary-yellow-500, #fc3);
            color: var(--grayscale-gray-800, #3d3d3d);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }
    }
}